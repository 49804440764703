export enum ERegistrationApplication {
  GET = 'regApp/getRegistrationApplications',
  GET_BY_ID = 'regApp/getRegistrationApplicationById',
  GET_MORE = 'regApp/getMoreRegistrationApplications',
  SEND_REGISTRATION_AVAILABLE_NOTIFICATION = 'regApp/sendRegistrationAvailableNotification',
}

export enum ERegistrationApplicationEndpoint {
  GET = '/admin/registration-requests',
  GET_BY_ID = '/admin/registration-requests/:id',
  SEND_REGISTRATION_AVAILABLE_NOTIFICATION = '/admin/registration-requests/send-registration-available-notification',
}
