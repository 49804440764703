import { createSlice } from '@reduxjs/toolkit';

import { SettingsSlice } from '@/types/slices';

import { getSettingsThunk, updateSettingThunk } from '../thunks';

const initialState: SettingsSlice = {
  settings: [],
  isSettingsLoading: false,
  isSettingUpdating: false,
};

export const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState,
  reducers: {
    clearSettings: (state) => {
      state.settings = [];
      state.isSettingsLoading = false;
      state.isSettingUpdating = false;
    },
    setIsSettingsLoading: (state, { payload }) => {
      state.isSettingsLoading = payload;
    },
    setIsSettingUpdating: (state, { payload }) => {
      state.isSettingUpdating = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSettingsThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.settings = payload;
        }
      })
      .addCase(updateSettingThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          const { key, value } = payload;

          state.settings = state.settings.map((setting) =>
            setting.key === key ? { ...setting, value } : setting
          );
        }
      });
  },
});
