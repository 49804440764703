import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { client } from '@/client';
import {
  PaginatedRegistrationApplications,
  RegistrationApplication,
  RegistrationApplicationParams,
  SendRegistrationAvailableNotificationPayload,
} from '@/types';

import {
  setIsMailSending,
  setIsRegistrationApplicationsLoading,
} from '../actions';
import {
  ERegistrationApplication,
  ERegistrationApplicationEndpoint,
} from '../constants';

export const getRegistrationApplications = createAsyncThunk(
  ERegistrationApplication.GET,
  async (
    params: RegistrationApplicationParams,
    { dispatch, rejectWithValue }
  ) => {
    dispatch(setIsRegistrationApplicationsLoading(true));
    try {
      const response = await client.get<PaginatedRegistrationApplications>(
        ERegistrationApplicationEndpoint.GET,
        {
          params,
        }
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      dispatch(setIsRegistrationApplicationsLoading(false));
    }
  }
);

export const getMoreRegistrationApplications = createAsyncThunk(
  ERegistrationApplication.GET_MORE,
  async (
    params: RegistrationApplicationParams,
    { dispatch, rejectWithValue }
  ) => {
    dispatch(setIsRegistrationApplicationsLoading(true));
    try {
      const response = await client.get<PaginatedRegistrationApplications>(
        ERegistrationApplicationEndpoint.GET,
        {
          params,
        }
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      dispatch(setIsRegistrationApplicationsLoading(false));
    }
  }
);

export const getRegistrationApplicationById = createAsyncThunk(
  ERegistrationApplication.GET_BY_ID,
  async (id: string, { dispatch, rejectWithValue }) => {
    dispatch(setIsRegistrationApplicationsLoading(true));
    try {
      const response = await client.get<RegistrationApplication>(
        ERegistrationApplicationEndpoint.GET_BY_ID.replace(':id', id)
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      dispatch(setIsRegistrationApplicationsLoading(false));
    }
  }
);

export const sendRegistrationAvailableNotification = createAsyncThunk(
  ERegistrationApplication.SEND_REGISTRATION_AVAILABLE_NOTIFICATION,
  async (
    body: SendRegistrationAvailableNotificationPayload,
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setIsMailSending(true));
      await client.post(
        ERegistrationApplicationEndpoint.SEND_REGISTRATION_AVAILABLE_NOTIFICATION,
        body
      );

      return body.registrationRequestIds;
    } catch (error) {
      const err = error as AxiosError | Error;
      return rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      dispatch(setIsMailSending(false));
    }
  }
);
