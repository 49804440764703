import { List, ListItem, useMediaQuery } from '@mui/material';
import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { showSideMenu } from '@/store/actions';
import { getNewCount } from '@/store/selectors';
import { theme } from '@/theme';

import { sideMenuWidth } from '../../constants';
import {
  EBadgeType,
  menuItems,
  menuItemsLilypad,
} from '../../constants/sideBarItems';
import { DrawerCustom, SideMenuListSubheader } from '../../styled';
import { SideMenuItem } from './MenuItem';

type Props = {
  isOpen: boolean;
};

export const SideMenu = ({ isOpen }: Props) => {
  const dispatch = useAppDispatch();
  const newCount = useAppSelector(getNewCount);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenuClose = useCallback(() => {
    dispatch(showSideMenu(false));
  }, [dispatch]);

  return (
    <DrawerCustom
      variant={isMobileScreen ? 'temporary' : 'permanent'}
      anchor="left"
      open={isOpen}
      sx={{
        width: isOpen ? sideMenuWidth : 0,
        '& .MuiDrawer-paper': {
          width: sideMenuWidth,
          justifyContent: 'space-between',
        },
      }}
      onClose={handleMenuClose}
    >
      <List subheader={<SideMenuListSubheader>Lilypad</SideMenuListSubheader>}>
        {menuItemsLilypad.map(({ text, url, icon, badge }) => (
          <ListItem key={text} disablePadding>
            <SideMenuItem
              text={text}
              url={url}
              icon={icon}
              badge={
                badge === EBadgeType.REPORT && newCount
                  ? newCount > 99
                    ? '+99'
                    : newCount.toString()
                  : undefined
              }
            />
          </ListItem>
        ))}
      </List>
      <List>
        {menuItems.map(({ text, url, icon }) => (
          <ListItem key={text} disablePadding>
            <SideMenuItem text={text} url={url} icon={icon} />
          </ListItem>
        ))}
      </List>
    </DrawerCustom>
  );
};
