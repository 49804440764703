import { Mail, MoreHoriz } from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
} from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';

import { ERegistrationApplicationStatus } from '@/types';

interface IProps {
  id: string;
  status: ERegistrationApplicationStatus;
  onSendEmail: () => void;
  onOpen?: () => void;
  onClose?: () => void;
}

export const GridOptionCell: FC<IProps> = ({
  id,
  status,
  onOpen,
  onClose,
  onSendEmail,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const menuItemText = useMemo(() => {
    const currentStatus =
      status === ERegistrationApplicationStatus.NEW ? 'Send' : 'Resend';

    return `${currentStatus} email`;
  }, [status]);

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
      onOpen?.();
    },
    [onOpen]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
    onClose?.();
  }, [onClose]);

  const onSendEmailHandler = useCallback(() => {
    onSendEmail();
    handleClose();
  }, [onSendEmail, handleClose]);

  return (
    <Box>
      <IconButton onClick={handleOpen}>
        <MoreHoriz />
      </IconButton>
      <Popover
        id={`context-menu-${id}`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onSendEmailHandler}>
          <ListItemIcon>
            <Mail />
          </ListItemIcon>
          <ListItemText>{menuItemText}</ListItemText>
        </MenuItem>
      </Popover>
    </Box>
  );
};
