export enum ESettingKey {
  IS_REGISTRATION_AVAILABLE = 'is-registration-available',
}

export enum ESettingValue {
  TRUE = '1',
  FALSE = '0',
}

export type Setting = {
  key: ESettingKey;
  value: ESettingValue;
  updatedAt: string;
};

export type UpdateSettingPayload = {
  value: ESettingValue;
  key: ESettingKey;
};
