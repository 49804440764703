import { Grid, Switch, Typography, useMediaQuery } from '@mui/material';
import { FC, useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  getIsSettingsLoading,
  getIsSettingUpdating,
  getSettingByKey,
  getShowSideMenu,
} from '@/store/selectors';
import { getSettingsThunk, updateSettingThunk } from '@/store/thunks';
import { theme } from '@/theme';
import { ESettingKey, ESettingValue } from '@/types';

import { StyledBox, StyledCard, StyledCircularProgress } from './styled';

export const LilypadSettings: FC = () => {
  const dispatch = useAppDispatch();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const openSideMenu = useAppSelector(getShowSideMenu);

  const isSettingsLoading = useAppSelector(getIsSettingsLoading);
  const isSettingUpdating = useAppSelector(getIsSettingUpdating);
  const registrationLimiterSetting = useAppSelector(
    getSettingByKey(ESettingKey.IS_REGISTRATION_AVAILABLE)
  );

  const itemWidth = isMobileScreen ? 12 : 6;
  const registrationLimiterChecked =
    registrationLimiterSetting?.value === ESettingValue.FALSE;

  const onRegistrationLimiterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      const value = checked ? ESettingValue.FALSE : ESettingValue.TRUE;

      dispatch(
        updateSettingThunk({
          key: ESettingKey.IS_REGISTRATION_AVAILABLE,
          value,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getSettingsThunk());
  }, [dispatch]);

  return (
    <Grid container>
      {isSettingsLoading ? (
        <Grid item>
          <StyledCircularProgress openSideMenu={openSideMenu} />
        </Grid>
      ) : null}
      <Grid item xs={itemWidth}>
        <StyledCard>
          <Typography variant="h5">Registration limiter</Typography>
          <StyledBox>
            <Switch
              disabled={isSettingUpdating}
              checked={registrationLimiterChecked}
              onChange={onRegistrationLimiterChange}
            />
            <Typography>Stop registration of new users</Typography>
          </StyledBox>
        </StyledCard>
      </Grid>
    </Grid>
  );
};
