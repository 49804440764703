import { GridRowId } from '@mui/x-data-grid';

import { ESortDirection, Pagination } from '.';

export enum ERegistrationApplicationStatus {
  NEW = 'new',
  SENT = 'sent',
}

export type RegistrationApplication = {
  id: string;
  email: string;
  mobilePhone: string;
  status: ERegistrationApplicationStatus;
  sentAt: string | null;
  createdAt: string;
};

export type PaginatedRegistrationApplications = {
  data: RegistrationApplication[];
  meta: Pagination;
};

export type RegistrationApplicationParams = {
  status?: ERegistrationApplicationStatus;
  orderBy?: string;
  orderDirection?: ESortDirection;
  search?: string;
  limit?: number;
  offset?: number;
};

export type SendRegistrationAvailableNotificationPayload = {
  registrationRequestIds: GridRowId[];
};
