export enum ESettings {
  GET = 'settings/getSettings',
  GET_BY_KEY = 'settings/getSettingByKey',
  UPDATE = 'settings/updateSetting',
}

export enum ESettingsEndpoint {
  SYSTEM_SETTINGS = '/admin/system-settings',
  SYSTEM_SETTING_BY_KEY = '/admin/system-settings/:key',
}
