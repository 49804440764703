import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { client } from '@/client';
import { Setting, UpdateSettingPayload } from '@/types';

import { setIsSettingsLoading, setIsSettingUpdating } from '../actions';
import { ESettings, ESettingsEndpoint } from '../constants';

export const getSettingsThunk = createAsyncThunk(
  ESettings.GET,
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setIsSettingsLoading(true));
    try {
      const response = await client.get<Setting[]>(
        ESettingsEndpoint.SYSTEM_SETTINGS
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      dispatch(setIsSettingsLoading(false));
    }
  }
);

export const getSettingByKeyThunk = createAsyncThunk(
  ESettings.GET,
  async (key: string, { dispatch, rejectWithValue }) => {
    dispatch(setIsSettingsLoading(true));
    try {
      const response = await client.get<Setting[]>(
        ESettingsEndpoint.SYSTEM_SETTING_BY_KEY.replace(':key', key)
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      return rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      dispatch(setIsSettingsLoading(false));
    }
  }
);

export const updateSettingThunk = createAsyncThunk(
  ESettings.UPDATE,
  async (body: UpdateSettingPayload, { dispatch, rejectWithValue }) => {
    dispatch(setIsSettingUpdating(true));
    try {
      await client.patch(ESettingsEndpoint.SYSTEM_SETTINGS, body);

      return body;
    } catch (error) {
      const err = error as AxiosError | Error;
      return rejectWithValue(
        'response' in err && err?.response?.data
          ? { error: err?.response?.data }
          : { error: err }
      );
    } finally {
      dispatch(setIsSettingUpdating(false));
    }
  }
);
