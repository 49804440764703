import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/types';

const registrationApplicationsState = (state: RootState) =>
  state.registrationApplications;

export const getRegistrationApplicationsList = createSelector(
  [registrationApplicationsState],
  ({ registrationApplications }) => registrationApplications
);

export const getRegistrationApplicationsTotalCount = createSelector(
  [registrationApplicationsState],
  ({ totalCount }) => totalCount
);

export const getIsRegistrationApplicationsLoading = createSelector(
  [registrationApplicationsState],
  ({ isContentLoading }) => isContentLoading
);

export const getIsRegistrationApplicationsMailSending = createSelector(
  [registrationApplicationsState],
  ({ isMailSending }) => isMailSending
);
