import { Box, Card, CircularProgress, styled } from '@mui/material';

import { sideMenuWidth } from '@/pages/Dashboard/constants';

interface StyledCircularProgressProps {
  openSideMenu?: boolean;
}

export const StyledCircularProgress = styled(
  CircularProgress
)<StyledCircularProgressProps>`
  position: absolute;
  top: 50%;
  left: ${({ openSideMenu }) =>
    openSideMenu ? `calc(50% + ${sideMenuWidth / 2}px)` : '50%'};
  transform: translate(-50%, -50%);
`;

export const StyledCard = styled(Card)`
  padding: 16px;
`;

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;
