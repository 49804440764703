import { Box, Button, IconButton, styled, Typography } from '@mui/material';

export const HeaderOutContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  ${theme.breakpoints.down('md')} {
    padding:  20px 16px 0;
    margin-bottom: 20px;
  }
`
);

export const InfoBox = styled(Box)`
  margin-right: 20px;
  flex-grow: 2;
  @media (max-width: 1100px) {
    margin-right: 0;
  }
`;

export const ActionBox = styled(Box)(
  ({ theme }) => `
  width: 200px;
  @media (max-width: 1100px) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`
);

export const ActionButton = styled(Button)(
  ({ theme }) => `
  min-width: 200px;
  &:not(:first-of-type) {
    margin-top: 10px;
  }
  &.noo-margin {
    margin-top: 0;
  }
  @media (max-width: 1100px) {
    margin-top: 0px;
    width: calc(50% - 5px);
    margin-bottom: 10px;
    &:not(:first-of-type) {
      margin-top: 0;
    }
    &:not(:nth-of-type(2n)) {
      margin-right: 10px;
    }
    &.full-size {
      width: 100%;
      margin-right: 0;
    }
  }
  ${theme.breakpoints.down('sm')} {
    width: 100%;
    &:not(:nth-of-type(2n)) {
      margin-right: 0;
    }
  }
`
);

export const ColumnDetail = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 100px;
  margin-right: 15px;

  ${theme.breakpoints.down('md')} {
    width: calc(33% - 15px);
    margin-bottom: 15px;
    &.report-id {
      width: calc(66% - 30px);
    }
  }

  ${theme.breakpoints.down('sm')} {
    width: calc(50% - 5px);
    margin-right: unset;
    &.report-id {
      width: calc(50% - 5px);
    }
  }

  &:not(:nth-child(2n)) {
    ${theme.breakpoints.down('sm')} {
      margin-right: 10px;
    }
  }
`
);

export const ColumnTitle = styled(Typography)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 6px;
`;

export const MessageImage = styled(Box)(
  ({ theme }) => `
  width: 250px;
  height: 250px;
  background-color: ${theme.palette.neutral?.dark};
  position: relative;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`
);

export const ResizeBlock = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${({ theme }) => theme.palette.neutral?.main};
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

export const SolutionBox = styled(Box)`
  border-radius: 4px;
  background: rgba(237, 108, 2, 0.1);
  padding: 10px;
  @media (max-width: 1100px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const Solution = styled(Typography)`
  opacity: 0.5;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  text-align: center;
`;

export const SolutionText = styled(Typography)`
  color: ${({ theme }) => theme.palette.warning.dark};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  text-align: center;
  margin: 0 10px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const ConfirmTitle = styled(Typography)`
  margin-top: 30px;
  margin-bottom: 14px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
`;

export const ConfirmText = styled(Typography)`
  text-align: center;
  margin-bottom: 30px;
`;

export const ConfirmButton = styled(Button)`
  width: 48%;
  min-width: unset;
`;

export const CancelButton = styled(ConfirmButton)`
  background-color: ${({ theme }) => theme.palette.grey[400]};
  color: ${({ theme }) => theme.palette.grey[700]};
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[500]};
  }
`;

export const MessageBox = styled(Box)`
  @media (max-width: 1100px) {
    margin-bottom: 20px;
  }
`;
