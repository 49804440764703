import { ReactNode } from 'react';
import { useMatch, useResolvedPath } from 'react-router-dom';

import { theme } from '@/theme';

import { MenuBadge, MenuItemLink, MenuItemText } from '../../styled';

type Props = {
  text: string;
  url: string;
  icon: ReactNode;
  badge?: string | null;
};

export const SideMenuItem = ({ text, url, icon, badge }: Props) => {
  const resolved = useResolvedPath(url);
  const match = !!useMatch({ path: resolved.pathname, end: true });

  return (
    <MenuItemLink
      to={url}
      sx={{
        backgroundColor: match
          ? theme.palette.custom.selected
          : theme.palette.neutral?.main,
        color: match ? theme.palette.primary.main : theme.palette.border?.light,
        height: 'auto',
      }}
    >
      {icon}
      <MenuItemText primary={text} />
      {badge ? <MenuBadge>{badge}</MenuBadge> : null}
    </MenuItemLink>
  );
};
