import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/types';

const settingsState = (state: RootState) => state.settings;

export const getSettings = createSelector(
  [settingsState],
  ({ settings }) => settings
);

export const getSettingByKey = (key: string) =>
  createSelector([getSettings], (settings) =>
    settings?.find((setting) => setting.key === key)
  );

export const getIsSettingsLoading = createSelector(
  [settingsState],
  ({ isSettingsLoading }) => isSettingsLoading
);

export const getIsSettingUpdating = createSelector(
  [settingsState],
  ({ isSettingUpdating }) => isSettingUpdating
);
