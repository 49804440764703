import {
  Add,
  Delete,
  Edit,
  MoreHoriz,
  Pause,
  PlayArrow,
  Search,
  Send,
} from '@mui/icons-material';
import {
  Box,
  Button,
  capitalize,
  CircularProgress,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Modal } from '@/components/common';
import { EmptyReport } from '@/components/common/GridFilterCustom/GridStyledComponents';
import { WarningBox } from '@/components/common/Modal/styled';
import { StatusChip } from '@/components/styled/Chip';
import {
  CancelButton,
  ConfirmButton,
  ConfirmText,
  ConfirmTitle,
} from '@/components/styled/ConfimModal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setEditedUserId } from '@/store/actions';
import { getAdminRole, getShowGlobalSpinner } from '@/store/selectors';
import { getAdminList, getEditedUser } from '@/store/selectors/admins';
import { deleteAdmin, editAdmin, getAdmins, sendInvite } from '@/store/thunks';
import { theme } from '@/theme';
import { RoleColor, StatusColor } from '@/theme/theming/statusesTheme';
import { EUserRole, EUserStatus, User } from '@/types';

import WarningSign from '../../../../assets/pictures/PopupBadge.svg';
import NoSearch from '../../../../assets/pictures/Search.svg';
import {
  SearchInput,
  TableContent,
  TablePreHeader,
  TableWrapper,
  TitlePreHeader,
} from '../../styled/TableWrapper';
import { CreateEditAdmins } from './CreateEditAdmin';

export const GridOptionCell: React.FC<
  GridRenderCellParams & { openModal: (open: boolean) => void }
> = (params) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const onEditAdmin = useCallback(() => {
    dispatch(setEditedUserId(params.row.id));
    handleClose();
    params.openModal(true);
  }, [dispatch, params]);

  const onSendInvite = useCallback(() => {
    dispatch(sendInvite(params.row.id.toString()));
  }, [dispatch, params]);

  const changeStatus = useCallback(() => {
    dispatch(
      editAdmin({
        id: params.row.id.toString(),
        data: {
          ...params.row,
          status:
            params.row.status === EUserStatus.ACTIVE
              ? EUserStatus.SUSPEND
              : EUserStatus.ACTIVE,
        },
      })
    );
  }, [dispatch, params.row]);

  const openConfirmModal = useCallback(() => {
    setIsOpenModal(true);
  }, []);

  const modalClose = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  const deleteUser = useCallback(() => {
    dispatch(deleteAdmin(params.row.id));
    modalClose();
    handleClose();
  }, [dispatch, modalClose, params.row.id]);

  return (
    <Box>
      <IconButton onClick={handleOpen}>
        <MoreHoriz />
      </IconButton>
      <Popover
        id={`context-menu-${params.row.id}`}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => onEditAdmin()}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        {params.row.status === EUserStatus.SUSPEND ||
        params.row.status === EUserStatus.ACTIVE ? (
          <MenuItem onClick={() => changeStatus()}>
            {params.row.status === EUserStatus.SUSPEND ? (
              <ListItemIcon>
                <PlayArrow fontSize="small" />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Pause fontSize="small" />
              </ListItemIcon>
            )}
            <ListItemText>
              {params.row.status === EUserStatus.SUSPEND
                ? 'Activate'
                : 'Suspend'}
            </ListItemText>
          </MenuItem>
        ) : null}

        {params.row.status === EUserStatus.PENDING ||
        params.row.status === EUserStatus.NEW ? (
          <MenuItem onClick={() => onSendInvite()}>
            <ListItemIcon>
              <Send />
            </ListItemIcon>
            <ListItemText>Resend Invite</ListItemText>
          </MenuItem>
        ) : null}

        {params.row.status === EUserStatus.PENDING ||
        params.row.status === EUserStatus.NEW ? (
          <MenuItem onClick={() => openConfirmModal()}>
            <ListItemIcon>
              <Delete color="error" fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ color: theme.palette.error.main }}>
              Delete User
            </ListItemText>
          </MenuItem>
        ) : null}
      </Popover>
      <Modal isOpen={isOpenModal} hasCloseIcon onClose={modalClose}>
        <WarningBox>
          <img src={WarningSign} />
          <ConfirmTitle>Delete The User</ConfirmTitle>
          <ConfirmText>Are you sure you want to delete the user?</ConfirmText>
          <Box display="flex" justifyContent="space-between" width="100%">
            <CancelButton
              className="cancel"
              variant="contained"
              onClick={() => modalClose()}
            >
              Cancel
            </CancelButton>
            <ConfirmButton
              variant="contained"
              onClick={() => deleteUser()}
              color="error"
            >
              Delete User
            </ConfirmButton>
          </Box>
        </WarningBox>
      </Modal>
    </Box>
  );
};

// eslint-disable-next-line react/no-multi-comp
const NoFilterResult = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="10px"
      height="100px"
    >
      <img src={NoSearch} />
      <EmptyReport>No admin found</EmptyReport>
    </Box>
  );
};

// eslint-disable-next-line react/no-multi-comp
const TableLoader = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="10px"
    >
      <CircularProgress />
    </Box>
  );
};

const rowsMapper = (admins: User[]) =>
  admins.map((admin) => ({
    id: admin.id,
    firstName: admin.firstName,
    lastName: admin.lastName,
    email: admin.email,
    role: admin.role,
    status: admin.status,
  }));

// eslint-disable-next-line react/no-multi-comp
export const Admins = () => {
  const dispatch = useAppDispatch();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [filteredAdmins, setFilteredAdmins] = useState<User[] | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const admins = useAppSelector(getAdminList);
  const editUser = useAppSelector(getEditedUser);
  const role = useAppSelector(getAdminRole);
  const isLoading = useAppSelector(getShowGlobalSpinner);

  useEffect(() => {
    setFilteredAdmins(admins);
  }, [admins]);

  const editModalOpen = useCallback(() => {
    setIsOpenModal(true);
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      valueGetter: ({ row }) => {
        return `${row.firstName || ''} ${row.lastName || ''}`;
      },
    },
    { field: 'email', headerName: 'Email', flex: 2 },
    {
      field: 'role',
      headerName: 'Role',
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return (
          <StatusChip
            sx={{
              marginLeft: 0,
              backgroundColor:
                RoleColor[`${params.value}`.toLowerCase() as EUserRole],
            }}
            label={params.row.role}
          />
        );
      },
      valueGetter: ({ row }) => {
        return capitalize(row.role);
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return (
          <StatusChip
            sx={{
              marginLeft: 0,
              backgroundColor:
                StatusColor[`${params.value}`.toLowerCase() as EUserStatus],
            }}
            label={
              params.row.status === EUserStatus.NEW
                ? 'Pending'
                : params.row.status
            }
          />
        );
      },
      valueGetter: ({ row }) => {
        return capitalize(row.status);
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return role && role === EUserRole.ADMIN ? (
          <GridOptionCell openModal={editModalOpen} {...params} />
        ) : null;
      },
    },
  ];

  useEffect(() => {
    dispatch(getAdmins());
  }, [dispatch]);

  const onModalStatusChange = useCallback((modalStatus: boolean) => {
    setIsOpenModal(modalStatus);
  }, []);

  const search = useCallback(
    (searchStr: string) => {
      if (admins) {
        if (!searchStr) {
          setFilteredAdmins(admins);
        } else {
          setFilteredAdmins(
            admins.filter(({ firstName, lastName }) =>
              `${firstName} ${lastName}`
                .toLowerCase()
                .includes(searchStr.toLowerCase())
            )
          );
        }
      }
    },
    [admins]
  );

  return (
    <TableWrapper>
      <Helmet>
        <title>Admins</title>
      </Helmet>
      <TablePreHeader
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <TitlePreHeader>Admins</TitlePreHeader>
        {role === EUserRole.ADMIN ? (
          <Button
            onClick={() => onModalStatusChange(true)}
            variant="contained"
            startIcon={<Add />}
          >
            Add New Admin
          </Button>
        ) : null}
      </TablePreHeader>
      <TableContent>
        {admins?.length ? (
          <Box>
            <SearchInput
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              id="search-admin"
              size="small"
              placeholder="Search..."
              variant="standard"
              type="search"
              onChange={(e) => search(e.target.value)}
            />
          </Box>
        ) : null}
        <div
          style={{
            width: '100%',
            height: filteredAdmins?.length ? 'unset' : 250,
          }}
        >
          <DataGrid
            rows={rowsMapper(filteredAdmins?.length ? filteredAdmins : [])}
            columns={columns}
            pageSizeOptions={[10, 20, 50]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            loading={isLoading}
            slots={{
              noRowsOverlay: NoFilterResult,
              loadingOverlay: TableLoader,
            }}
          />
        </div>
        <CreateEditAdmins
          isOpen={isOpenModal}
          editUser={editUser}
          onClose={() => onModalStatusChange(false)}
        />
      </TableContent>
    </TableWrapper>
  );
};
