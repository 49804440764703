import { EmptyReport } from '@/components/common/GridFilterCustom/GridStyledComponents';

import NoSearch from '../../../../assets/pictures/Search.svg';
import { NoResultsStyled } from './styled';

export const NoResults = () => {
  return (
    <NoResultsStyled>
      <img src={NoSearch} />
      <EmptyReport>No registration applications found</EmptyReport>
    </NoResultsStyled>
  );
};
