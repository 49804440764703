import { Search } from '@mui/icons-material';
import { Box, Button, debounce, InputAdornment } from '@mui/material';
import {
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRowId,
  GridSortModel,
} from '@mui/x-data-grid';
import { isFulfilled } from '@reduxjs/toolkit';
import { format, parseISO } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';

import {
  CustomFilterIcon,
  GridFilterCustom,
} from '@/components/common/GridFilterCustom';
import { StatusChip } from '@/components/styled/Chip';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { ToastService } from '@/services';
import {
  getIsRegistrationApplicationsLoading,
  getIsRegistrationApplicationsMailSending,
  getRegistrationApplicationsList,
  getRegistrationApplicationsTotalCount,
} from '@/store/selectors';
import {
  getMoreRegistrationApplications,
  getRegistrationApplications,
  sendRegistrationAvailableNotification,
} from '@/store/thunks';
import { RegistrationApplicationStatusColor } from '@/theme/theming/statusesTheme';
import {
  ERegistrationApplicationStatus,
  ESortDirection,
  RegistrationApplication,
  RegistrationApplicationParams,
} from '@/types';

import {
  SearchInput,
  TableContent,
  TablePreHeader,
  TableWrapper,
  TitlePreHeader,
} from '../../styled/TableWrapper';
import { GridOptionCell } from './GridOptionCell';
import { NoResults } from './NoResults';
import { StyledDataGrid, TableNavBar } from './styled';

const title = 'Applications for registration';
const pageSizeOptions = [10, 15, 50];
const slots = {
  columnMenu: GridFilterCustom,
  noResultsOverlay: NoResults,
  noRowsOverlay: NoResults,
  columnMenuIcon: CustomFilterIcon,
};

const formatDateOrDash = (date?: string) => {
  return date ? format(parseISO(date), 'dd/MM/yyyy') : '-';
};

const rowsMapper = (registrationApplications: RegistrationApplication[]) =>
  registrationApplications.map(({ id, email, createdAt, status, sentAt }) => ({
    id,
    email,
    createdAt,
    status,
    sentAt,
  }));

export const RegistrationApplications: FC = () => {
  const dispatch = useAppDispatch();

  const totalCount = useAppSelector(getRegistrationApplicationsTotalCount);
  const isLoading = useAppSelector(getIsRegistrationApplicationsLoading);
  const isEmailSending = useAppSelector(
    getIsRegistrationApplicationsMailSending
  );
  const registrationApplications = useAppSelector(
    getRegistrationApplicationsList
  );

  const [filterModel, setFilterModel] = useState<GridFilterModel>();

  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [queryParams, setQueryParams] =
    useState<RegistrationApplicationParams>();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const filterField = filterModel?.items[0]?.field;

  const onSendEmail = useCallback(
    async (registrationRequestIds: GridRowId[], clearSelected?: boolean) => {
      if (isEmailSending) {
        return;
      }

      const response = await dispatch(
        sendRegistrationAvailableNotification({
          registrationRequestIds,
        })
      );
      if (isFulfilled(response)) {
        const messageText = `${
          registrationRequestIds.length > 1 ? 'Emails' : 'Email'
        } sent successfully`;

        ToastService.success(messageText);

        clearSelected && setSelectedRows([]);
      }
    },
    [dispatch, isEmailSending]
  );

  const resetPagination = useCallback(() => {
    setPaginationModel((prev) => ({
      ...prev,
      page: 0,
    }));
  }, []);

  const fetchRegistrationApplications = useCallback(
    async (params: RegistrationApplicationParams, fetchMore?: boolean) => {
      const newQueryParams = { ...queryParams, ...params };

      setQueryParams(newQueryParams);

      const action = fetchMore
        ? getMoreRegistrationApplications(newQueryParams)
        : getRegistrationApplications(newQueryParams);

      const response = await dispatch(action);

      if (isFulfilled(response) && !fetchMore) {
        resetPagination();
      }
    },
    [dispatch, resetPagination, queryParams]
  );

  const debouncedFetch = useMemo(
    () =>
      debounce(
        (searchParams: RegistrationApplicationParams, fetchMore?: boolean) => {
          fetchRegistrationApplications(searchParams, fetchMore);
        },
        300
      ),
    [fetchRegistrationApplications]
  );

  const onOpenOptionCell = useCallback((id: GridRowId) => {
    setSelectedRows((prev) => prev.filter((rowId) => rowId !== id));
  }, []);

  const onFilterModelChange = useCallback(
    (gridFilterModel: GridFilterModel) => {
      const { items } = gridFilterModel;

      const filterParams = {
        status: items[0]?.value,
        offset: 0,
      };

      setFilterModel(gridFilterModel);
      fetchRegistrationApplications(filterParams);
    },
    [fetchRegistrationApplications]
  );

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      const { field, sort } = sortModel[0] || {};

      const orderDirection = sort?.toUpperCase() as ESortDirection | undefined;
      const sortParams = {
        orderBy: field,
        orderDirection,
        offset: 0,
      };

      fetchRegistrationApplications(sortParams);
    },
    [fetchRegistrationApplications]
  );

  const onSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchParams = {
        search: event.target.value,
        offset: 0,
      };

      setSearchString(searchParams.search);

      debouncedFetch(searchParams);
    },
    [debouncedFetch]
  );

  const onPaginationModelChange = useCallback(
    async (pagination: GridPaginationModel) => {
      const { page, pageSize } = pagination;
      const { pageSize: currentPageSize } = paginationModel;

      const paginationParams = {
        offset: page * pageSize,
        limit: pageSize,
      };

      const fetchMore = currentPageSize === pageSize;

      const isPageBeyondCurrentLength =
        (page + 1) * pageSize > registrationApplications.length;
      const hasMoreData = registrationApplications.length < totalCount;
      const shouldLoadMore = isPageBeyondCurrentLength && hasMoreData;

      setPaginationModel(pagination);

      if (!shouldLoadMore) {
        setQueryParams(paginationParams);

        return;
      }

      fetchRegistrationApplications(paginationParams, fetchMore);
    },
    [
      fetchRegistrationApplications,
      registrationApplications.length,
      paginationModel,
      totalCount,
    ]
  );

  const onRowSelectionModelChange = useCallback((ids: GridRowId[]) => {
    setSelectedRows(ids);
  }, []);

  const rowsData = useMemo(() => {
    const { page, pageSize } = paginationModel;
    const startIndex = page * pageSize;
    const endIndex = startIndex + pageSize;

    const rows = registrationApplications.slice(startIndex, endIndex);

    return rowsMapper(rows);
  }, [registrationApplications, paginationModel]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'email',
        headerName: 'Email',
        flex: 2,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'createdAt',
        headerName: 'Date',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        valueFormatter: ({ value }) => formatDateOrDash(value),
      },
      {
        field: 'status',
        headerName: 'Notice Status',
        flex: 1,

        sortable: true,
        type: 'singleSelect',
        valueOptions: Object.values(ERegistrationApplicationStatus).map(
          (val) => ({
            value: val,
            label: val === ERegistrationApplicationStatus.NEW ? 'New' : 'Sent',
          })
        ),
        renderCell: ({ value }) => (
          <StatusChip
            sx={{
              marginLeft: 0,
              backgroundColor:
                RegistrationApplicationStatusColor[
                  `${value}`.toLowerCase() as ERegistrationApplicationStatus
                ],
            }}
            label={value}
          />
        ),
      },
      {
        field: 'sentAt',
        headerName: 'Date of Email Sending',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        valueFormatter: ({ value }) => formatDateOrDash(value),
      },
      {
        field: 'actions',
        type: 'actions',
        width: 50,
        renderCell: (params) => (
          <GridOptionCell
            id={params.row.id}
            status={params.row.status}
            onOpen={() => onOpenOptionCell(params.row.id)}
            onSendEmail={() => onSendEmail([params.row.id])}
          />
        ),
      },
    ];
  }, [onOpenOptionCell, onSendEmail]);

  useEffect(() => {
    dispatch(getRegistrationApplications({}));
  }, [dispatch]);

  return (
    <TableWrapper>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <TablePreHeader>
        <TitlePreHeader>{title}</TitlePreHeader>
      </TablePreHeader>
      <TableContent>
        <TableNavBar>
          <SearchInput
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            id="search-admin"
            size="small"
            placeholder="Search..."
            variant="standard"
            type="search"
            value={searchString}
            onChange={onSearchChange}
          />
          <Button
            variant="contained"
            disabled={!selectedRows.length}
            onClick={() => onSendEmail(selectedRows, true)}
          >
            {`Send email ${
              selectedRows.length ? `(${selectedRows.length})` : ''
            }`}
          </Button>
        </TableNavBar>

        <Box height={rowsData.length ? 'unset' : 250}>
          <StyledDataGrid
            filterField={filterField}
            rows={rowsData}
            columns={columns}
            slots={slots}
            rowCount={totalCount}
            pageSizeOptions={pageSizeOptions}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            sortingMode="server"
            onSortModelChange={onSortModelChange}
            filterMode="server"
            filterModel={filterModel}
            onFilterModelChange={onFilterModelChange}
            loading={isLoading}
            checkboxSelection
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={onRowSelectionModelChange}
          />
        </Box>
      </TableContent>
    </TableWrapper>
  );
};
